@import "../../styles/base/mixin";
@import "../../styles/base/colors";

.contact-radio-label {
  cursor: pointer;
  padding-left: 25px;
  line-height: 35px;
  font-size: 1.5rem;
  color: $black-grey;
  @include media(">tablet") {
    padding-bottom: 10px;
  }
}

.contact-radio-input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.radio-button-control {
  margin-left: -30px;
  margin-top: 7px;
  position: absolute;
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  background-color: inherit;
  color: #1e00c8;
  border: 1px solid #1e00c8;
  border-radius: 24px;
}

.contact-radio-input:checked+.radio-button-control:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 14px;
  height: 14px;
  background-color: #1e00c8;
  border-radius: 12px;
}

.contact-radio-input:checked+.radio-button-control {
  border-color: $primary;
}

.radio-button-control {
transform: scale(0.75);
}