@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.pictogrammes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.pictogrammes-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  text-align: center;
  h3 {
    margin: 0;
    font-size: 0.9rem;
    text-transform: uppercase;
    margin-top: 10px;
  }
  p {
    font-size: 13px;
    margin-top: 5px;
  }
  @include media(">tablet") {
    width: 20%;
    align-content: center;
    h3 {
      margin-top: 20px;
      font-size: 1.3rem;
      text-transform: uppercase;
    }
    p {
      margin: auto;
      margin-top: 20px;
      font-size: 1rem;
    }
  }
}

.pictogrammes-card-image {
  height: 110px;
  width: 110px;
  object-fit: contain;
  @include media(">tablet") {
    height: 180px;
    width: 180px;
  }
}

.pictogrammes-container {
  @include media(">tablet") {
    margin-bottom: 30px;
  }
}
