@import "../../styles/base/mixin";
@import "../../styles/base/colors";


.choice-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 35px;
  @include media(">=tablet") {
    justify-content: unset;
  }
}

.choice-card {
  height: 145px;
  width: 145px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  color: $black-grey;
  text-align: center;
  border: 1px solid $primary;
  border-radius: 15px;
  font-size: 1rem;
  @include media(">=tablet") {
    font-size: 1.5rem;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 200px;
    height: 200px;
  }
}

.-active {
  color: white;
  background-color: $primary;
}