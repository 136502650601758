@import "../../styles/base/colors";
@import "../../styles/base/mixin";

#wcaw-logo {
  height: 60px;
  @include media(">tablet") {
    height: 130px;
  }
}

#linkedin-navbar-logo {
  height: 30px;
}

#menu-logo {
  height: 30px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0 20px;
  max-width: 1800px;
  margin: auto;
  &-buttons {
    display: flex;
    justify-content: space-between;
  }

  @include media(">tablet") {
    height: 165px;
    padding-left: 20px;
  }
}

#parlons-en {
  @include media(">tablet") {
    display: block !important;
    text-decoration: none;
    background: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 30px;
    color: #f8cc04;
    border: 1px solid #f8cc04;
    border-radius: 15px;
    padding: 4px 40px;
  }
}