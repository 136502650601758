$dark-blue: rgb(30, 0, 200);
$light-blue: rgb(88, 187, 249);
$tangerine-yellow: rgb(252, 206, 7);
$pastel-pink: rgb(255, 161, 152);
$black-grey: rgba(75, 75, 75, 1);
$shadow-grey: rgb(204, 203, 203);
$white: rgba(255,255,255,1);
$red-home: rgb(247, 94, 82);

$primary: $dark-blue;
$secondary: $tangerine-yellow