@import "../../styles/base/mixin";
@import "../../styles/base/colors";

.sidenav {
  margin-right: -375px;
  height: 100%;
  width: 375px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: $primary;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  .sidenav-link {
    text-align: end;
    padding: 20px 50px 10px 0;
    text-decoration: none;
    font-size: 1.4rem;
    color: #ffffff;
    display: block;
    transition: 0.3s;
    &:nth-child(2) {
      margin-top: 25px;
    }
    &:hover {
      color: #f1f1f1;
    }
  }
}

.-active {
  font-weight: bold;
  .active-marker {
    position: absolute;
    right: 0;
    width: 13px;
    height: 28px;
    background-color: #ffffff;
  }
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  transition: 0.3s;
  color: #ffffff;
  font-size: 4rem;
  font-weight: bold;
}

.burger-menu {
  cursor: pointer;
  margin: 0 15px;
  border: 0;
  background: none;
  width: 35px;
  height: 35px;
  background-image: url(../../assets/icons/burgerIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
  &:focus{
    outline: none;
  }
}
