@import '../../styles/base/colors';

.logo-card{
    display: inline-block;
    padding: 6px;
    margin-right: 12px;
    margin-bottom: 12px;
    border: $black-grey solid 1px;
    border-radius: 10px;
}
.logo-card-image{
    width: 100px;
    height: 100px;
    object-fit: contain;
}