@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.footer {
  display: flex;
  flex-direction: column;
  color: $primary;
  @include media(">tablet") {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
  }
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
  a {
    font-size: 15px;
    text-decoration: none;
    width: 50%;
    margin-bottom: 10px;
  }
  @include media(">tablet") {
    padding: 0 40px;
    a {
      margin-bottom: 30px;
      width: 33%;
    }
  }
}

.footer-social {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.footer-social-title {
  width: 50%;
  @include media(">tablet") {
    p {
      margin: 0;
    }
  }
}

.footer-social-logos {
  display: flex;
  flex-wrap: wrap;
  @include media(">tablet") {
    margin-top: 20px;
    flex-direction: column;
  }
}

.footer-social-container{
  display: flex;
  flex-direction: column;
  width: 45%;
  @include media(">tablet") {
    flex-direction: row;
    width: 100%;
  }
}

.footer-social-item {
  display: flex;
  align-items: center;
  width: 45%;
  margin-bottom: 6px;
  @include media(">tablet") {
    width: 34%;
    margin-bottom: 10px;
  }
}

.footer-social-logo {
  height: 35px;
}

.footer-social-label {
  margin-left: 10px;
  @include media(">tablet") {
    display: none;
  }
}
.footer-social-container-title{
  margin: 0;
  margin-bottom: 8px;
  @include media("<tablet") {
    display: none;
  }

}
