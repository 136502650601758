@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.offre-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 42vw;
  width: 42vw;
  margin: 5px;
  border: 1px solid $primary;
  border-radius: 15px;
  padding: 8px;
  font-size: 0.8rem;
  max-height: 210px;
  max-width: 210px;
  @include media(">tablet") {
    font-size: 0.9rem;
    margin: 12px;
    width: 12vw;
    height: 12vw;
    min-height: 140px;
    min-width: 140px;
  }
}

.offre-card-text {
  margin: 0;
  color: $primary;
  text-align: center;
}
.offre-card-img {
  object-fit: contain;
  width: 33%;
  height: 33%;
  margin-bottom: 8px;
}
