@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.main-offres {
  margin: 0;
  padding: 0 10px;
  max-width: 1800px;
  color: $black-grey;
  @include media (">tablet") {
    margin: auto;
    padding: 0 100px;
  }
}

.offres-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  >p {
    margin: 10px 0;
  }
  @include media (">tablet") {
    margin: 40px 0;
    >p {
      width: 43%;
      font-size: 1.2rem;
    }
  }
}

.offres-exemples {
  margin: 50px 0;
}

.offres-exemples-notes {
  color: $black-grey;
  text-align: right;
  //padding-right: 40px;
  line-height: 34px;
  vertical-align: middle;
  .inline-logo{
    height: 34px;
    margin-bottom: -12px;
    object-fit: contain;
  }
}

.offres-exemples-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}