@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.article {
  margin: 30px 0;
  &.-right {
    text-align: right;
  }
  &.-left {
    text-align: left;
  }
  &.-center {
    text-align: center;
  }
  &.-margin-left {
    margin-left: 30px;
    @include media (">=desktop") {
      margin-left: 50px;
    }
  }
  &.-margin-right {
    margin-right: 30px;
    @include media (">=desktop") {
      margin-right: 50px;
    }
  }
}
