@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.separator-container {
  margin: 30px 0;
  display: flex;
  justify-content: flex-end;
  @include media("<tablet") {
    margin-left: -35px !important;
    margin-right: -35px !important;
  }
}
.separator {
  width: 50%;
  max-width: 400px;
  height: 70px;
  &.-primary {
    background-color: $primary;
  }
  &.-red {
    background-color: $red-home;
  }
  @include media("<tablet") {
    height: 50px;
  }
}
