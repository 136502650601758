@import "../styles/base/colors";
@import "../styles/base/mixin";

.home {
  padding: 0 35px;
  margin: auto;
  max-width: 1800px;
  @include media(">=tablet") {
    padding: 50px 100px;
  }
}

.home-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  &.-elearning {
    @include media("<=tablet") {
      flex-direction: column;
      align-items: center;
    }
  }
  &.-center{
    margin-top: 30px;
    justify-content: center;
  }
  @include media(">=desktop") {
    > article {
      width: 40%;
    }
  }
}
