@import "../../styles/base/colors";
@import "../../styles/base/fonts";
@import "../../styles/base/mixin";

.elearncard {
  display: flex;
  height: 90vw;
  border-radius: 20px;
  background: no-repeat center right 13% / cover
    url("../../assets/images/mobile/aside-homepage.png");
  box-shadow: inset 0 0 0 2000px rgba($color: $primary, $alpha: 0.36);
  @include media(">=tablet", "<desktop") {
    height: 50vw;
  }
  @include media(">=desktop") {
      display: flex;
      height: 40vw;
      width: 40vw;
      max-height: 750px;
      max-width: 750px;
      min-height: 500px;
      min-width: 500px;
      background: no-repeat center right 13% / cover
        url("../../assets/images/desktop/aside-homepage.png");
    }
}

.elearncard-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.elearncard-content-text {
  width: 80%;
  color: white;
  font-family: black-jack;
  font-size:2.5rem;
  text-align: center;
  @include media(">=desktop") {
    text-align: center;
    font-size: 3rem;
  }
}

#play-btn {
  position: absolute;
  background-color: $tangerine-yellow;
  border-radius: 15px;
  margin-top: 115px;
  @include media(">=desktop") {
      height: 72px;
      width: 72px;
      margin-top: 210px;
      margin-left: -60px;
      border-radius: 36px;
  }
}