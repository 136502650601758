@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.soutien-section {
  background: no-repeat center top 7% / cover url("../../assets/images/mobile/footer-homepage.jpg");
  box-shadow: inset 0 0 0 2000px rgba($color: $primary, $alpha: 0.36);
  color: #ffffff;
  margin-top: 50px;
  text-align: right;
  @include media(">tablet") {
    background: no-repeat center top 7% / cover url("../../assets/images/desktop/footer-homepage.jpg");
  }
}

.soutien-content {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 1800px;
  padding: 50px 60px;
  @include media(">tablet") {
    padding: 100px 200px;
  }
  article:first-child {
    @include media(">=desktop") {
      width: 45%;
    } 
  }
}