@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.enjeux-interview {
  display: flex;
  flex-direction: column;
}

.enjeux-interview-aside {
  align-self: center;
  > img {
    width: 100%;
  }
}

.enjeux-interview-title {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.enjeux-interview-job {
  margin-top: 0;
  font-size: 1.3rem;
}

.interview-question {
  color: $pastel-pink;
  font-weight: bold;
  font-size: 1.2rem;
}

@include media (">=desktop") {
  .enjeux-interview-section {
    width: 70%;
    padding: 0;
    padding-left: 45px;
  }

  .enjeux-interview-aside {
    > img {
      width: 400px;
      height: 400px;
    }
  }

  .enjeux-interview {
    flex-direction: row;
    padding: 45px 0 20px 0;
  }
  .enjeux-interview-title {
    font-size: 1.9rem;
  }
  

  .interview-question {
    font-size: 1.7rem;
  }

  .interview-answer {
    font-size: 1.3rem;
  }
}

@include media(">=tablet", "<desktop") {
  .enjeux-interview-section {
    padding-top: 20px;
  }

  .enjeux-interview-aside {
    > img {
      width: 400px;
      height: 400px;
    }
  }

  .enjeux-interview {
    align-items: center;
    padding: 45px 30px 20px 30px;
  }
}
