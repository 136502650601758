@import "../../styles/base/mixin";
@import "../../styles/base/colors";

.video-player {
  width: 100%;
  height: 60vw;
  max-width: 750px;
  max-height: 400px;
  box-shadow: 0 5px 10px $shadow-grey;
  @include media(">tablet") {
    width: 40vw;
    margin-top: 37px;
  }
}