@import "../../styles/base/mixin";
@import "../../styles/base/colors";

.enjeux-article {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
}

.enjeux-articles-aside {
  align-self: center;
  > img {
    width: 100%;
  }
}

.enjeux-articles-section {
  padding: 10px 0;
}

.enjeux-article-title {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.enjeux-article-job {
  margin-top: 0;
  font-size: 1.3rem;
}

.enjeux-article-link {
  font-size: 1rem;
  font-weight: bold;
  color: $light-blue;
  > a {
    font-weight: normal;
  }
}

@include media(">=desktop") {
  .enjeux-article {
    flex-direction: row;
  }

  .enjeux-articles-section {
    width: 70%;
    padding: 0;
    padding-left: 45px;
  }

  .enjeux-article-title {
    font-size: 1.9rem;
  }

  .enjeux-article-text {
    font-size: 1.3rem;
  }

  .enjeux-articles-aside {
    > img {
      width: 400px;
      height: 400px;
    }
  }
}

@include media(">=768", "<desktop") {
  .enjeux-article {
    align-items: center;
  }

  .enjeux-articles-aside {
    > img {
      width: 400px;
      height: 400px;
    }
  }

  .enjeux-articles-section {
    padding-top: 20px;
  }
}
