@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.dash {
  display: inline-block;
  height: 10px;
  width: 25px;
  margin-top: 18px;
  @include media(">tablet") {
    height: 15px;
    width: 40px;
    margin-top: 25px;
  }
  &.-left {
    margin-left: -30px;
    margin-right: 5px;
    @include media(">tablet") {
      margin-left: -50px;
      margin-right: 10px;
    }
  }
  &.-right {
    margin-right: -30px;
    margin-left: 5px;
    @include media(">tablet") {
      margin-right: -50px;
      margin-left: 10px;
    }
  }
  &.-primary {
    background-color: $primary;
  }

  &.-light-blue {
    background-color: $light-blue;
  }

  &.-red {
    background-color: $red-home;
  }
}
