@import "../../styles/base/fonts";
@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.newsletters {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletters-title {
  margin-bottom: 0;
  color: $primary;
  font-size: 1.2rem;
  @include media(">tablet") {
    font-size: 2.5rem;
  }
}

.newsletters-text {
  font-family: "black-jack";
  margin-top: 0;
  color: #ea8c86;
  font-size: 0.9rem;
  text-align: center;
  @include media(">tablet") {
    font-family: "black-jack";
    font-size: 1.9rem;
  }
}

.newsletters-input {
  width: 80%;
  padding: 15px;
  border-color: $primary;
  border-width: 0.1px;
  @include media(">tablet") {
    width: 30%;
    height: 40px;
  }
}

.newsletters-input::placeholder {
  @include media(">tablet") {
    font-size: 20px;
    color: #cccbcb;
  }
}

.newsletters-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @include media(">tablet") {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 60px;
  }
}

.newsletters-button {
  margin: 20px;
  background: $primary;
  border: 2px solid $primary;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.1rem;
  @include media(">tablet") {
    margin-top: 10px;
  }
}
