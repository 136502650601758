@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.team-card {
  width: 45%;
  margin: 15px 0;
  margin-right: 15px;
  text-align: left;
  @include media(">tablet") {
    width: unset;
  }
}

.team-card-img {
  width: 100%;
  display: block;
  float: left;
  filter: grayscale(100%);
  z-index: 1;
  @include media(">tablet") {
    width: unset;
  }
}

.team-card-picture {
  display: flex;
  position: relative;
  z-index: 0;
  &:hover .team-card-hover {
    display: block;
  }
  .team-card-hover {
    height: 100%;
    width: 100%;
    position: absolute;
    display: none;
    z-index: 2;
    padding: 5px 20px;
    font-size: 0.6rem;
    color: $white;
    text-align: left;
    border-radius: 10px;
    box-shadow: inset 0 0 0 2000px rgba(30, 0, 200, 0.36);
    a:last-child {
      margin: 10px 0;
      @include media("<=phone") {
        margin: 2px 0;
        font-size: 0.6rem;
        padding: 0px 3px;
      }
    }
    @include media("<=phone") {
      line-height: 10px;
    }
    @include media(">=tablet") {
      padding: 45px 20px;
      font-size: 1rem;
    }
  }
}

.team-card-hover-title {
  margin: 5px 0;
  font-weight: bold;
  text-transform: uppercase;
  @include media(">=desktop") {
    font-size: 1rem;
  }
}

.team-card-hover-text {
  margin: 0;
  @include media(">=desktop") {
    font-size: 1rem;
  }
}

.team-card-name {
  margin: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.8rem;
  @include media(">=tablet", "<desktop") {
    font-size: 1rem;
  }
  @include media(">=desktop") {
    font-size: 1.4rem;
  }
}

.team-card-info {
  margin: 0;
  color: $black-grey;
  font-size: 0.7rem;
  @include media(">=tablet", "<desktop") {
    width: 260px;
    font-size: 0.9rem;
  }
  @include media(">=desktop") {
    width: 260px;
    font-size: 1.2rem;
  }
}
