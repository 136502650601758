@import "../../styles/base/mixin";

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;
  @include media (">tablet") {
    justify-content: center;
  }
}