@import "../../styles/base/fonts";
@import "../../styles/base/mixin";

.slider-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
  padding: 20px;
  margin: 12px;
  max-height: 300px;
  overflow: hidden;
  border-radius: 10px;
  &:focus {
    outline: none;
  }
  .card-header {
    display: flex;
    flex-direction: row;
  }

  .card-header-text {
    flex-direction: column;
  }

  .slider-card-title {
    margin: 0;
    color: #58bbf9;
  }

  .slider-card-subtitle {
    margin: 0;
    color: #cccbcb;
  }

  .card-content {
    font-size: 1.2rem;
    height: 100%;
    font-family: black-jack;
    text-align: center;
    color: #58bbf9;
    margin: 0;
    margin-top: 12px;
  }

  .card-photo {
    object-fit: cover;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    margin-right: 10px;
    @include media("<=tablet") {
      height: 50px;
      width: 50px;
    }
  }
}
