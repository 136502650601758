@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.nsc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  box-shadow: 0 3px 6px $shadow-grey;
  border: 1px solid $primary;
  border-radius: 10px;
  padding: 25px;
  text-align: center;
  @include media (">=desktop") {
    position: relative;
    margin: 15px;
    width: 320px;
    height: 330px;
    >a {
      position: absolute;
      bottom: 20px;
    }
  }
}

.nsc-img {
  width: 70px;
  height: 70px;
}

.nsc-title {
  font-size: 1.3rem;
}

.nsc-text {
  margin: 0;
  margin-bottom: 15px;
  font-size: 1rem;
}