@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.nos-solutions {
  padding: 35px;
  margin: auto;
  max-width: 1800px;
  color: $black-grey;
  @include media(">=desktop") {
    padding: 50px 100px;
  }
}

.nos-solutions-section {
  display: flex;
  justify-content: center;
  align-items: center;
  &.-items {
    flex-direction: column;
    @include media(">=desktop") {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

.nos-solutions-text {
  font-size: 1rem;
  @include media(">=desktop") {
    font-size: 1.5rem;
    width: 50%;
  }
}
