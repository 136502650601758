@import "../../styles/base/mixin";

.slick-slider {
  width: 100%;
  margin-bottom: 40px;
  @include media(">tablet") {
    margin: 0;
    width: 40vw;
    max-width: 600px;
    min-width: 400px;
  }
}
.slick-slider .slick-slide *:focus {
  outline: none;
}
