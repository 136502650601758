@import "../../styles/base/colors";
@import "../../styles/base/mixin";



.link-button{
    display: inline-block;
    background: none;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid $white;
    box-shadow: rgba($color: #000000, $alpha: 0.16) 0 3px 6px;
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px 10px;
    font-size: 1rem;
    &.-primary{
        border-color: $primary;
        color: $primary;
    }
    &.-secondary{
        border-color: $secondary;
        color: $secondary;
    }
    &.-disabled-link {
        cursor: not-allowed;
        border-color: $shadow-grey;
        color: $shadow-grey;
    }
}
