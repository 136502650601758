@import "./styles/base/mixin";
@import "./styles/base/colors";


* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.page-section {
  padding: 0 100px;
  margin: 25px 0;
  color: $black-grey;
  > p {
    @include media (">=desktop"){
      font-size: 1.5rem;
      width: 50%;
    }
  }
  @include media ("<tablet"){
    padding: 0 35px;
  }
  &.-center{
    text-align: center;
  }
  &.-right{
    text-align: right;
  }
  &.-flex {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
