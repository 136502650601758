@import "../../styles/base/mixin";
@import "../../styles/base/colors";


.main-contact {
  padding: 35px;
  max-width: 1800px;
  @include media(">tablet") {
    margin: auto;
    padding: 140px;
    padding-bottom: 100px;
  }
}

.contact-radio-container {
  margin-bottom: 35px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 15px;
  color: $black-grey;
  border: 1px solid $primary;
  border-radius: 15px;
}

.transition-dash {
  display: block;
  margin: auto;
  width: 35%;
  height: 1px;
  padding: 0;
  border: 0;
  margin-bottom: 30px;
  @include media(">tablet") {
    margin-bottom: 70px;
  }
}
.other-queries {
  font-size: 0.9rem;
  >.email{
    color: $light-blue;
  }
}