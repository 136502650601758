@import "../../styles/base/mixin";
@import "../../styles/base/colors";

.contact-form {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  @include media(">tablet") {
    font-size: 1.5rem;
    flex-direction: row;
  }
}

.contact-form-details {
  display: flex;
  flex-direction: column;
  background-color: $primary;
  color: #fff;
  padding: 50px 30px;
  border-radius: 10px 10px 0 0;
  @include media(">tablet") {
    width: 45%;
    border-radius: 10px 0 0 10px;
  }
}

.contact-form-label {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.contact-form-input {
  padding-top: 10px;
  color: #fff;
  border: none;
  background: 0;
  font-size: 1.2rem;
}

.contact-form-description {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  padding-bottom: 20px;
  border: 1px solid;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
  color: $primary;
  @include media(">tablet") {
    display: block;
    width: 55%;
    padding: 50px 30px;
    border-radius: 0 10px 10px 0;
  }
}

.contact-form-textarea {
  margin-top: 10px;
  border: 1px solid;
  height: 300px;
}

textarea:focus,
input:focus {
  outline: none;
}

.submit-button {
  background: none;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid $secondary;
  box-shadow: rgba($color: #000000, $alpha: 0.16) 0 3px 6px;
  color: $secondary;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 30px;
  margin-top: 20px;
  font-size: 1.1rem;
}

label >hr {
  display: block;
  height: 1px;
  border: 0;
  padding: 0;
  margin: 0px;
}
