@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.title {
  font-size: 1.6rem;
  letter-spacing: 0.05rem;
  margin: 10px 0;
  @include media(">tablet") {
    font-size: 2.5rem;
  }

  &.-primary {
    color: $primary;
  }

  &.-light-blue {
    color: $light-blue;
  }

  &.-pink {
    color: $pastel-pink;
  }

  &.-red {
    color: $red-home;
  }

  &.-white {
    color: white;
  }
}