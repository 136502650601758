@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.enjeux-main {
  color: $black-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 35px;
  max-width: 1800px;
  @include media(">=desktop") {
    padding: 100px;
  }
}

.enjeux-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}

.enjeux-title {
  font-size: 1.7rem;
  font-weight: bold;
  color: $pastel-pink;
  @include media(">=desktop") {
    font-size: 1.9rem;
  }
  @include media(">=tablet", "<desktop") {
    font-size: 2.2rem;
  }  
}

.-talk {
  > p {
    margin-bottom: 25px;
    text-align: center;
  }
  @include media(">=desktop") {
    padding: 45px 0;
    > p {
      font-size: 1.3rem;
    }
  }
  @include media(">=tablet", "<desktop") {
    padding: 10px 30px;
  }
}

.article-title {
  margin: 0;
  font-size: 1.7rem;
  font-weight: bold;
  color: $pastel-pink;
  @include media(">=desktop") {
    font-size: 3rem;
  }
  @include media(">=tablet", "<desktop") {
    font-size: 1.9rem;
  }
}