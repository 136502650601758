@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.metric {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media(">=tablet", "<desktop") {
    justify-content: center;
    //flex-direction: row;
    padding-bottom: 0;
    padding: 10px 0;
  }

  @include media(">=desktop") {
      justify-content: center;
      flex-direction: row;
      padding-bottom: 0;
      &.metric-reverse {
        flex-direction: row-reverse;
      }
  }
}


.metric-text-content {
  display: flex;
  flex-direction: column;
  &.-left {
    text-align: end;
  }
  @include media(">=desktop") {
    width: 60%;
    padding-left: 80px;
    font-size: 1.3rem;
    &.-left {
      text-align: end;
      padding-right: 80px;
    }
  }
  @include media(">=tablet", "<desktop") {
    &.-left {
     // text-align: center;
    }
  }
}


.circle-metric {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 310px;
  height: 310px;
  border-radius: 50%;
  background-color: $pastel-pink;
  font-weight: bold;
  > p {
    font-size: 2rem;
    color: white;
    > span {
      font-size: 7rem;
    }
  }

  @include media(">=desktop") {
    width: 390px;
    height: 390px;
  } 
}