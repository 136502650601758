@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.qsn-container {
  padding: 35px;
  color: $black-grey;
  max-width: 1800px;
  @include media(">tablet") {
    font-size: 1.4rem;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 40px;
    padding: 0 100px;
  }
  article:last-child {
    text-align: right;
    @include media(">=desktop") {
      margin-left: 50%;
    }
  }
}

.qsn-section {
  padding-bottom: 40px; 
  &.-team {
    text-align: center;
  }
}