@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.legal-info-container {
    padding: 35px;
    color: $black-grey;
    max-width: 1800px;
    @include media(">tablet") {
        font-size: 1.4rem;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 40px;
        padding: 0 100px;
    }
}
