@import "../../styles/base/colors";
@import "../../styles/base/mixin";

.header-container {
  display: flex;
  align-items: center;
  height: 88vh;
  color: $white;
  background: no-repeat center right 13% / cover
    url("../../assets/images/mobile/header-homepage.png");
  box-shadow: inset 0 0 0 2000px rgba($color: $primary, $alpha: 0.36);
  @include media(">tablet") {
    height: 71vh;
    background: no-repeat center bottom 100% / cover
      url("../../assets/images/desktop/header-homepage.png");
  }
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 50px 20px;
  margin-left: 30px;
  @include media(">tablet") {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 0;
  }
}

.header-content-title {
  margin: 0 0 15px 0;
  font-size: 1.9rem;
  @include media(">tablet") {
    width: 65%;
    font-size: 2.5rem;
  }
}

.header-mid {
  @include media(">tablet") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
