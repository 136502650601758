@import "../../styles/base/mixin";
@import "../../styles/base/colors";

.banner {
  text-align: center;
  width: 100%;
  height: 400px;
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 2000px rgba($color: $primary, $alpha: 0.36);
  @include media (">tablet") {
    padding: 0 100px;
    height: 300px;
  }
  p{
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 1.8rem;
  }
  .link-button{
    margin-top: 15px;
  }
}